import SendMsg2Server from "./Requests";

class MessageParser {
  constructor(actionProvider, state) {
    console.log("New MessageParser object")
    this.actionProvider = actionProvider;
    this.state = state;
  }

  async parse(message) {
    try {

      const productPortfolio = this.state.product_portfolio || null;
      const chatState = this.state.chat_state || null;
      const response = await SendMsg2Server(message, productPortfolio, chatState);
      
      this.actionProvider.handleServerResponse(response);

      console.log(this.state)

    } catch (error) {
      console.error("Error parsing message:", error);
    }
  }
}

export default MessageParser;
