import React, { useState } from "react";

import { useAuth } from "utils/AuthContext";
import SolarbaerLogo from "assets/solarbaer-logo.png";

import {
  LoginContainer,
  LoginBox,
  Logo,
  Title,
  LoginForm,
  ErrorMessage,
  InputGroup,
  Label,
  Input,
  LoginButton,
} from "./styled";

const Login = () => {
  const { login } = useAuth();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();
    await login(username, password, setErrorMessage);
  };

  return (
    <LoginContainer>
      <LoginBox>
        <Logo src={SolarbaerLogo} alt="Solarbaer Logo" />
        <Title>SolarBär GmbH - Manager</Title>
        <LoginForm onSubmit={handleLogin}>
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          <InputGroup>
            <Label>
              Username:
              <Input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </Label>
          </InputGroup>
          <InputGroup>
            <Label>
              Password:
              <Input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </Label>
          </InputGroup>
          <LoginButton type="submit">Log In</LoginButton>
        </LoginForm>
      </LoginBox>
    </LoginContainer>
  );
};

export default Login;
