// AuthGuard.js
import React from "react";

import { useAuth } from "utils/AuthContext";
import Login from "components/Login/Login";

const AuthGuard = ({ children }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? children : <Login />;
};

export default AuthGuard;
