import React, { createContext, useContext, useState } from "react";

import { apiRoutes } from "./apiRoutes";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const hasToken = !!localStorage.getItem("token");

  const [isAuthenticated, setIsAuthenticated] = useState(hasToken);

  const login = async (username, password, setErrorMessage) => {
    setErrorMessage("");

    try {
      const response = await fetch(apiRoutes.login, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });

      if (!response.ok) {
        if (response.status === 400) {
          setErrorMessage("Incorrect username or password.");
        } else if (response.status === 401) {
          setErrorMessage('Session expired. Please log in again.');
        } else {
          throw new Error("Network response was not ok");
        }
        return;
      }

      const data = await response.json();
      if (data.token) {
        localStorage.setItem("token", data.token);
        setIsAuthenticated(true);
      } else {
        setErrorMessage("Login failed. Please try again.");
      }
    } catch (error) {
      setErrorMessage("An error occurred during login. Please try again.");
      console.error("Error during login:", error);
    }
  };

  const logout = () => {
    localStorage.removeItem("token");
    setIsAuthenticated(false);
  };

  window.addEventListener("beforeunload", (e) => {
    e.preventDefault();
    logout();
  });

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
