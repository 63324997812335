// in config.js
import { createChatBotMessage } from 'react-chatbot-kit';

const botName = 'SolarBot';

const config = {
  initialMessages: [createChatBotMessage(`Nachname des Kunden:`)],
  botName: botName,
  customStyles: {
    botMessageBox: {
      backgroundColor: '#10143C',
    },
    chatButton: {
      backgroundColor: '#89BAE3',
    },
  },
};

export default config;
