import { apiRoutes } from "utils/apiRoutes";

const sendPostRequest = async (message, product_portfolio, state) => {
  const data = {
    key: {
      user_message: message,
    }
  };

  if (product_portfolio !== null) {
    data.key.product_portfolio = product_portfolio;
  }
  
  if (state !== null) {
    data.key.state = state;
  }

  console.log(data)

  try {
    const token = localStorage.getItem("token");

    if (!token) {
      throw new Error("No token found");
    }

    const response = await fetch(apiRoutes.chat, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      if (response.status === 401) {
        // Redirect to login page if 401 response is detected
        window.location.href = '/login';
      } else {
        throw new Error('Network response was not ok');
      }
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    throw error;
  }
};

export default sendPostRequest;
