import React from "react";

import { AuthProvider } from "./utils/AuthContext";
import ChatBot from "./components/Chatbot/Chatbot";
import AuthGuard from "./components/AuthGuard";

import { AppContainer, ChatbotContainer } from "./styled";

const App = () => {
  return (
    <AuthProvider>
      <AppContainer>
        <AuthGuard>
          <ChatbotContainer>
            <ChatBot />
          </ChatbotContainer>
        </AuthGuard>
      </AppContainer>
    </AuthProvider>
  );
};

export default App;
