class ActionProvider {
  constructor(createChatBotMessage, setState) {
    this.createChatBotMessage = createChatBotMessage;
    this.setState = setState;
  }

  handleServerResponse = (response) => {

    // Destructure the necessary fields from the response
    const { next_question, options } = response;

    let message = next_question
    if (options && options.length > 0) {
      message += " " + options.join(", ");
    }

    const botMessage = this.createChatBotMessage(message);
    this.setState((prev) => ({
      ...prev,
      chat_state: response.state,
      product_portfolio: response.product_portfolio,
      messages: [...prev.messages, botMessage],
    }));
  };
}

export default ActionProvider;
